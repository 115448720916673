<template>
  <div class="cate">
<!--    <RecommendPost class="aa" v-if="recommendPostList.length" :recommendPostList="recommendPostList" />-->
    <div class="option">
      <div class="title">热门帖子</div>
      <div class="op_tol" >
        <div class="op_item" @click="smallNavClick(index, item.value)" :class="[currentSmallIndex === index ? 'act' :'']"  v-for="(item, index) in smallNav">{{item.name}}</div>
      </div>
    </div>
    <el-row :gutter="20" class="cate_content" v-if="dataList.length">
      <el-col :xs="10" :sm="10" :md="10" :lg="8" :xl="8" v-for="item in dataList" :key="item.id" >
        <ArticleListItem :key="item.id" :currentPage="currentPage" :orderType="orderType"
                         :title="item.title" content="content" :createTime="item.createTime" :updateTime="item.updateTime" :summary="item.summary" :imagePath="item.imagePath" :article_id="item.id"/>
        <!--        <HotSpeakItem :key="item.id" :title="item.title" content="content" :createTime="item.createTime" :updateTime="item.updateTime" :summary="item.summary" :imagePath="item.imagePath" :article_id="item.id"/>-->
      </el-col>
    </el-row>

    <div class="page_">
      <el-pagination
              @current-change="currentChange"
              layout="prev, pager, next"
              :page-size="pageSize"
              :current-page="currentPage"
              :total="total">
      </el-pagination>

    </div>


  </div>
</template>

<script>
  import HotSpeakItem from '@/components/item/HotSpeakItem.vue'
  import ArticleListItem from '@/components/item/ArticleListItem'
  import SpeakItem from '@/components/item/SpeakItem'
  import RecommendPost from '@/components/recommendPost/RecommendPost.vue'
  import { getHotSpeak } from '@/utils/network'
  export default {
    data() {
      return {
        //分页
        total:null,
        currentPage:this.$store.state.isLastPage?parseInt(this.$store.state.lastPage):1,
        pageSize:12,
        x:0,
        dataList:[],
        currentSmallIndex:this.$store.state.isLastPage?this.$store.state.orderType!='createtime'?this.$store.state.orderType==='likecount'?1:2:0:0,
        alterTitle:"加载中...",
        orderType:this.$store.state.isLastPage?(this.$store.state.orderType):'createtime',
        smallNav:[
          {name:'发布日期', value: 'createtime'},
          {name:'点赞最多', value: 'likecount'},
          {name:'精华', value:'essence'}
        ],
        recommendPostList:[]
      }
    },
    created() {
      this.getData()
      this.getRecommendData()
      this.$store.dispatch('isLastPage', {
        isLastPage: false
      })
      this.$store.dispatch('orderType', {
        orderType: 'createtime'
      })
    },
    methods: {
      currentChange(index) {
        this.currentPage = index
        this.getData()
        window.scrollTo(0, 0);
      },
      smallNavClick(index, orderType) {
        this.currentSmallIndex = index
        this.orderType = orderType
        this.currentPage = 1
        this.getData()
      },
      async getData() {
        this.alterTitle="加载中..."
        const { data: res } = await getHotSpeak({
          page:this.currentPage,
          per_page:this.pageSize,
          orderType:this.orderType,
          sourceType:'web'
        })
        if (res.code !== 200) return  this.$message.error('请求失败')
        this.dataList = res.data.vodata
        this.total = res.data.total
       // console.log(this.dataList)
      },
      async getRecommendData() {
        const { data: res } = await getHotSpeak({
          page:1,
          per_page:7,
          orderType:'createtime',
          sourceType:'web'
        })
        if (res.code !== 200) return  this.$message.error('请求失败')
        this.recommendPostList = res.data.vodata
       // console.log(this.recommendPostList)
      }

    },
    components: {
      SpeakItem,
      HotSpeakItem,
      RecommendPost,
      ArticleListItem
    }
  }
</script>

<style lang="less" scoped>
  .cate {
    min-width: 1224px;
    width: 80%;
    min-height: 670px;
    margin: 20px auto ;
    background-color: #fff;
    padding: 0 0 20px 0;
    aa {
      width: 100%;
    }

    overflow: hidden;

    .option {
      background-color: #fff;
      padding: 0 12px;
      display: flex;
      //margin: 10px;
      height: 40px;
      line-height: 40px;
      justify-content: space-between;
      .title {
        font-weight: 700;
        font-size: 18px;
      }
      .op_tol{
        display: flex;
        width: 280px;
        justify-content: space-around;
        font-size: 14px;
        color: rgba(0, 0, 0, .6);
        .act {
          color: red;
        }
      }
    }

    .cate_content {
      background-color: #fff;
      //display: flex;
      //justify-content: space-around;
      //flex-wrap: wrap;
      min-height: 525px;
      .page {
        margin: 0 auto;
      }

    }

  }

</style>
