<template>
  <div>
    <div class="recommend">
      <div class="rec_title">推荐帖子</div>
      <div class="rec_main">
        <i class=" iconfont icon-zuojiantou icon iconl" @click="toLeft"></i>
        <div class="rec_main_content" ref="content">
          <SpeakItem  class="rec_main_content_item" v-for="item in recommendPostList" :key="item.id" :title="item.title":imagePath="item.imagePath" :summary="item.summary" @click.native="$router.push(`/detailPost/${item.id}`)"/>
        </div>
        <i class=" iconfont icon-youjiantou icon iconr " @click="toRight"></i>

      </div>
    </div>
  </div>
</template>

<script>
  import SpeakItem from '@/components/item/SpeakItem'
  export default {
    data() {
      return {
        x:0
      }
    },
    mounted() {
      // console.log(this.recommendPostList)
    },
    props:{
      recommendPostList:{
        type:Array,
        required:true,
        default() {
          return []
        }
      }

    },
    methods: {
      toLeft() {
        if(this.x <= 0) return

        this.x -= 300
        this.listScroll(this.x)
      },

      toRight() {
        if(this.x >= 900) return
        this.x += 300
        this.listScroll(this.x)

      },
      listScroll(x) {
        this.$nextTick(() => {
          this.$refs.content.scrollTo({
            top: 0,
            left:x,
            behavior: "smooth"
          })
        })
      }
    },
    components: {
      SpeakItem
    }
  }
</script>

<style lang="less" scoped>
  .recommend {
    min-width: 1224px;
    background-color: #fff;
    margin-bottom: 20px;
    padding-bottom: 12px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, .3);
    .rec_title {
      padding: 0 12px;
      font-weight: 700;
      font-size: 18px;
      height: 40px;
      line-height: 40px;
    }
    .rec_main {
      width: 100%;
      display: flex;
      position: relative;
      align-items: center;
      .rec_main_content {
        //width: 99%;
        overflow-x: scroll;
        overflow-y: hidden;
        display: flex;
        margin: 0 26px;
        flex-wrap: nowrap;
        justify-content: space-between;
        transition: all .4s;
        &::-webkit-scrollbar {
          display: none;
        }
        .rec_main_content_item {
          margin-right: 30px;
        }
      }
      .icon {
        cursor: pointer;
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        font-size: 26px;
        width: 20px;
        height: 100px;
        line-height: 100px;
        background-color: rgba(0, 0, 0, .3);
        z-index: 99;
      }
      .iconl {
        left: 0;
      }
      .iconr {
        right: 0;
      }

    }
  }

</style>
