<template>
  <div class="ev_item" @click="goDetail">
    <div class="ev_left">
      <img :src="imagePath" alt="">
    </div>
    <div class="ev_right">
      <div class="ev_right_top overflow" style="font-weight: 700; font-size: 18px; margin-bottom: 4px;">{{title}}</div>
      <div style="color: #797979; font-size: 12px; " class="overflow3">{{summary}}</div>
      <p style="font-size: 14px;">发布时间：{{createTime | dataFormat}}</p>
      <p style="font-size: 14px;"> 最后回复：{{updateTime | dataFormat}}</p>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    props:{
      imgUrl:{
        type:String
      },
      title:{
        type:String,
      },
      summary:{
        type:String,
      },
      createTime:{
        type:String
      },
      updateTime:{
        type:String
      },
      article_id:{
        type:Number,
        required:true
      },
      imagePath:{
        type:String
      }
    },
    methods: {
      goDetail() {
        this.$router.push(`/detailPost/${this.article_id}`)
      }
    },
    components: {}
  }
</script>

<style lang="less" scoped>
  .ev_item {
    padding: 12px 6px;
    width: 400px;
    height: 157px;
    box-sizing: border-box;
    display: flex;
    margin:0 8px;
    justify-content: space-around;
    margin-bottom: 12px;
    border: 1px #ccc solid;
    &:hover {
      box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .2);
    }
    .ev_left{
      margin-right: 10px;
      img{
        height: 128px;
        width: 140px;
        object-fit: cover;
      }
    }
    .ev_right {
      width: 260px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      //align-items: first;
    }

  }

</style>
